<template>
  <div>
    <a-modal
      :closable="false"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel(selectedList)"
      :width="960"
      okText="导出"
    >
      <template #title>
        <p class="title">导出数据</p>
        <a-button class="all_btn_border all" @click="checkedList">全选</a-button>
        <a-button class="all_btn_border clear" @click="clearcheckedList">清除已选字段</a-button>
      </template>
      <div class="optional">
        <p class="title">可选字段</p>
        <ul class="content" v-if="dataList">
          <li v-for="(item,index) in dataList" :key="index">
            <p>{{item.title}}</p>
            <a-checkbox @change="onChange(ite)" :disabled="ite.disabled" :checked="ite.checked" v-for="(ite,ind) in item.options" :key="ind">
              {{ite.name}}
            </a-checkbox>
          </li>
        </ul>
      </div>
      <div class="selected">
        <p class="title">已选字段</p>
        <!-- <div class="contents">
          <div class="selectItem" v-for="(item,index) in selectedList" :key="index">
            <p>{{item.name}}</p>
            <p class="delItem" @click="deleteItem(index)" v-if="!item.disabled">清除</p>
          </div>
        </div> -->
        <a-table :columns="columns"
          :showHeader="false"
          :pagination="false"
          :data-source="selectedList"
          :rowKey="(item,index)=>index"
          :customRow="customRow"
          :scroll="{ y: 600 }"
          bordered>
          <template slot="name" slot-scope="text">
            <a-icon type="more" class="icon"/><a-icon type="more" class="icon rightIcon"/>{{text}}
          </template>
          <template slot="operation" slot-scope="text, row, index">
            <p class="delItem" @click="deleteItem(index)" v-if="!row.disabled">清除</p>
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "",
    align: "center",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "",
    align: "center",
    width: "70px",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: [
    'visible',
    'handleCancel',
    'dataList',
    'onexportList',
    'selList'
  ],
  // 数据对象
  data () {
    return {
      columns,
      confirmLoading: false,
      selectedList:[],
      sourceObj:null,
      oldIndex:'',
      targetObj:null,
      // dataList:[
      //   {
      //     title:'基本信息',
      //     options:[
      //       {
      //         name:'用户姓名',
      //         checked: false,
      //         disabled: false
      //       },
      //       {
      //         name:'用户账号',
      //         checked: false,
      //         disabled: false
      //       },
      //       {
      //         name:'昵称',
      //         checked: false,
      //         disabled: false
      //       },
      //       {
      //         name:'角色',
      //         checked: false,
      //         disabled: false
      //       },
      //       {
      //         name:'工作单位',
      //         checked: false,
      //         disabled: false
      //       }
      //     ]
      //   },
      //   {
      //     title:'订单信息',
      //     options:[
      //       {
      //         name:'收货人手机号',
      //         checked: false,
      //         disabled: false
      //       },
      //       {
      //         name:'用户账号',
      //         checked: false,
      //         disabled: false
      //       },
      //       {
      //         name:'昵称',
      //         checked: false,
      //         disabled: false
      //       },
      //       {
      //         name:'订单编号',
      //         checked: true,
      //         disabled: true
      //       },
      //       {
      //         name:'工作单位',
      //         checked: false,
      //         disabled: false
      //       }
      //     ]
      //   }
      // ]
    }
  },
  // 事件处理器
  methods: {
    customRow (record,index) {
      return {
        // FIXME: draggable： true 先使用鼠标移入事件设置目标行的draggable属性
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: 'pointer'
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            // 兼容IE
            var ev = event || window.event
            ev.target.draggable = true
          },
          // 开始拖拽
          dragstart: (event) => {
            // 兼容IE
            var ev = event || window.event
            // 阻止冒泡
            ev.stopPropagation()
            // 得到源目标数据
            this.sourceObj = record
            this.selectedList.map((item,index)=>{
              if(item.name == record.name){
                this.oldIndex = index;
              }
            })
          },
          // 拖动元素经过的元素
          dragover: (event) => {
            // 兼容 IE
            var ev = event || window.event
            // 阻止默认行为*/
            ev.preventDefault()
          },
          // 鼠标松开
          drop: (event) => {
            // console.log(333333333333)
            // 兼容IE
            var ev = event || window.event
            // 阻止冒泡
            ev.stopPropagation()
            // 得到目标数据
            this.targetObj = record;
            this.selectedList.splice(this.oldIndex,1);
            for(let i=0;i<this.selectedList.length;i++){
              if(this.selectedList[i].name == record.name){
                this.selectedList.splice(i,0,this.sourceObj);
                return;
              }
            }
            // let data2 = this.data1.filter(item=>item.id === this.sourceObj.id)
            // // 过滤出来源目标数据
            // this.data1 = this.data1.filter(item=>item.id !== this.sourceObj.id)
            // // 过滤出来除了源数据的数据
            // this.data1.splice(index, 0,...data2)
            // 将源数据插入到相应的数据中去
          }
        }
      }
    },
    checkedList(){
     for(let i=0;i<this.dataList.length;i++){
        for(let j=0;j<this.dataList[i].options.length;j++){
          this.dataList[i].options[j].checked = true;
          this.selectedList.push(this.dataList[i].options[j]);
        }
      }
    },

    // 清楚已选字段
    clearcheckedList(){
      for(let i=0;i<this.dataList.length;i++){
        for(let j=0;j<this.dataList[i].options.length;j++){
          this.dataList[i].options[j].checked = false;
          this.selectedList = [];
        }
      }
    },
    // 删除选中项
    deleteItem(e){
      for(let i=0;i<this.dataList.length;i++){
        let index = this.dataList[i].options.indexOf(this.selectedList[e]);
        if(index != '-1'){
          this.dataList[i].options[index].checked = false;
          this.selectedList.splice(e,1);
          return;
        }
      }
    },
    // 选中、取消选中时间
    onChange(ite){
      // console.log(ite)
      if(ite.checked){
        ite.checked = false;
        let index = this.selectedList.indexOf(ite)
        this.selectedList.splice(index,1)
      }else{
        ite.checked = true;
        this.selectedList.push(ite);
      }
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      if(!this.selectedList.length){
        return this.$message.error('请选择导出字段')
      }
      this.$emit('onexportList',this.selectedList)
    },
    // handleCancel(e) {
    // console.log('Clicked cancel button');
    //   this.visible = false;
    // },
  },
  // 生命周期-实例创建完成后调用
  created () {
    if(this.selList){
      return this.selectedList = JSON.parse(JSON.stringify(this.selList));
    }
    let arr = [];
    for(let i=0;i<this.dataList?.length;i++){
      for(let j=0;j<this.dataList[i].options.length;j++){
        if(this.dataList[i].options[j].checked){
          arr.push(this.dataList[i].options[j])
        }
      }
    }
    this.selectedList = arr;
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    dataList(newVal,oldVal){
      let arr = [];
      for(let i=0;i<newVal?.length;i++){
        for(let j=0;j<newVal[i].options.length;j++){
          if(newVal[i].options[j].checked){
            arr.push(newVal[i].options[j])
          }
        }
      }
      this.selectedList = arr;
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/.ant-table-tbody > tr > td{
    border-right: 0 !important;
    padding: 9px 16px !important;
  }
  /deep/.ant-table-tbody > tr > td:first-child{
    text-align: left !important;
    .icon{
      width: 5px;
      height: 14px;
      font-size: 15px;
    }
    .rightIcon{
      margin-right: 10px;
    }
  }
  /deep/.ant-modal-body{
    padding-top: 32px;
    display: flex;
    justify-content: space-between;
    .optional{
      width: 560px;
    }
    .selected{
      width: 306px;
    }
    .optional,.selected{
      .title{
        border-radius: 5px 5px 0 0;
        padding: 10px;
        background: #F6F6FC;
        font-weight: bold;
        color: #333;
      }
      .content{
        border-radius: 0 0 5px 5px;
        border: 1px solid #eee;
        border-top: 0;
        padding: 10px;
        height: 600px;
        overflow-y: auto;
        li{
          margin-bottom: 20px;
          p{
            font-weight: bold;
            margin-bottom: 15px;
          }
        }
      }
      .contents{
        border-radius: 0 0 5px 5px;
        border: 1px solid #eee;
        height: 600px;
        overflow-y: auto;
      }
      .selectItem{
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
      }
    }
  }
  .delItem{
    font-size: 12px;
    color: #2B6ED4;
    line-height: 14px;
    cursor: pointer;
  }
  /deep/.ant-checkbox-wrapper{
    margin-left: 0 !important;
    width: 33.3%;
    margin-bottom: 10px;
  }
  /deep/.ant-modal-header{
    border: 0;
  }
  /deep/.ant-modal-footer{
    text-align: center;
    border: 0;
  }
  /deep/.ant-modal-title{
    text-align: center;
    position: relative;
    .title{
      font-size: 18px;
      font-weight: bold;
      color: #000000;
      line-height: 21px;
    }
    .clear{
      position: absolute;
      right: 0;
      top: 0;
      border-color: #3681EF;
      color: #3681EF;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }
    .all{
      position: absolute;
      right: 128px;
      top: 0;
      border-color: #3681EF;
      color: #3681EF;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }
  }
</style>
