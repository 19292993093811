<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 证书查询 </template>
      <template #input>
        <a-input placeholder="请输入姓名" v-model="name" />
        <a-input placeholder="请输入手机号" v-model="mobile" />
        <a-input placeholder="请输入身份证号" v-model="idcard" />
        <a-select mode="multiple" placeholder="请选择身份" v-model="userType" showArrow>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="0">普通用户</a-select-option>
          <a-select-option :value="1">家长</a-select-option>
          <a-select-option :value="2">从业者</a-select-option>
          <a-select-option :value="3">机构</a-select-option>
        </a-select>
        <a-input placeholder="请输入考试名称" v-model="examName" />
        <a-input placeholder="请输入证书名称" v-model="certifName" />
        <a-select placeholder="请选择证书类型" allowClear v-model="certifType">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="1">中康协认证证书</a-select-option>
          <a-select-option value="2">课程学习证明</a-select-option>
          <a-select-option value="5">CEU学分证明</a-select-option>
          <a-select-option value="6">自研课程证书</a-select-option>
          <a-select-option value="3">个人上传证书</a-select-option>
        </a-select>
        <a-input placeholder="请输入课程名称" v-model="courseName" />
        <a-range-picker
          :placeholder="['发证开始时间', '发证结束时间']"
          format="YYYY-MM"
          :mode="dateMode"
          v-model="findtime"
          @panelChange="handlePanelChange2"
          @change="handleChange"
        />
        <!-- valueFormat="YYYY-MM-DD HH:MM:SS" -->
        <!-- <a-range-picker
          :placeholder="['Start month', 'End month']"
          format="YYYY-MM"
          :value="value"
          :mode="['month', 'month']"
        /> -->
        <a-button type="primary" class="btn" :loading="loading" @click="onSearch"
          >搜索</a-button
        >
        <a-button type="primary" class="btn" v-hasPermi="['certificates:examineecertif:export']" @click="isExportShow = true"
          >导出</a-button
        >
        <a-button type="primary" class="btn" @click="onExportLink"
          >导出二维码链接</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :scroll="{ x: true }"
        :rowKey="(item) => item.certifId"
        :columns="columns"
        :data-source="tableData"
        @change="onPage"
        :loading="loading"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="footer">
          <div style="height: 30px"></div>
        </template>
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="mobile" slot-scope="text, item">
        {{ item.mobile ? $encryption('empty','phone',item.mobile) : "--" }}
        </template>
        
        <template slot="examName" slot-scope="item, row">
            {{ row.certifType == 4?'-':item }}
        </template>

        <template slot="examDate" slot-scope="item, row">
            {{ row.certifType == 4?'-':item }}
        </template>

        <template slot="operation" slot-scope="text, record">
          <div class="blueText">
            <span @click="lookImg(record.picture)">查看 </span>
            <span>|</span>
            <span @click="getDown(record.picture, '图片')"> 下载 </span>
            <span v-if="record.certifType == 1">|</span>
            <span v-if="record.certifType == 1" @click="getUser(record)"> 更换证书信息</span>
          </div>
        </template>
      </a-table>
      {{ certifItem.name }}
    </div>

    <Derive ref="export"
      :visible="isExportShow"
      :handleCancel="modelCancel"
      @onexportList="onexportList()"
      :dataList="[
        {
          title: '证书信息',
          options: exportList,
        },
      ]"
    />
    <!-- 导出弹窗 -->
    <!-- <a-modal v-model="isExportShow" title="导出数据" okText="确认导出" width="850px" @ok="onexportList">
      <div class="modalDiv">
        <p>请选择表格中需要的字段内容：</p>
        <a-checkbox-group v-model="checkedList">
          <a-row>
            <a-col :span="4" v-for="(item,index) in exportList" :key="index">
              <a-checkbox :checked="true" :value="item.value">
                {{item.name}}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
        <span></span>
      </div>
    </a-modal> -->
    <!-- 更换证书信息 -->
    <a-modal v-model="isModifyShow" align="center" title="更新证书信息">
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name">证书编号：</span>
          <a-input
            class="right"
            placeholder="请输入证书编号"
            v-model="modelForm.certifcode "
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">发证时间：</span>
          <a-month-picker
            class="right"
            placeholder="请选择发证时间"
            valueFormat="YYYY-MM"
            format="YYYY-MM"
            v-model="modelForm.getcertifTime"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">姓名：</span>
          <a-input
            class="right"
            placeholder="请输入姓名"
            :disabled="true"
            v-model="modelForm.name"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">性别：</span>
          <a-radio-group name="radioGroup" v-model="modelForm.sex">
            <a-radio :value="1">男</a-radio>
            <a-radio :value="0">女</a-radio>
          </a-radio-group>
        </div>
        <div class="line-item">
          <span class="all_left_name">出生日期：</span>
          <a-date-picker
            class="right"
            valueFormat="YYYY-MM-DD"
            v-model="modelForm.birthday"
            placeholder="请选择出生日期"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">身份证号：</span>
          <a-input
            class="right"
            placeholder="请输入身份证号"
            :disabled="true"
            v-model="modelForm.idcard"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">学历：</span>
          <a-input
            class="right"
            placeholder="请输入学历"
            v-model="modelForm.education"
          />
          <!-- <a-select class="right" placeholder="" v-model="modelForm.education" allowClear>
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option value="1">本科</a-select-option>
          </a-select> -->
        </div>
        <div class="line-item">
          <span class="all_left_name">职位/职称：</span>
          <a-input
            class="right"
            placeholder="请输入职位/职称"
            v-model="modelForm.post"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">工作单位：</span>
          <a-input
            class="right"
            placeholder="请输入工作单位"
            v-model="modelForm.workUni"
          />
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="changeCertifMessage">确认</a-button>
        <a-button @click="cancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import Derive from "@/components/derive.vue"; // 导出组件
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
    fixed: "left",
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  // {
  //   title: "身份证号",
  //   align: "center",
  //   dataIndex: "idcard",
  // },
  {
    title: "课程名称",
    align: "center",
    dataIndex: "courseName",
  },
  {
    title: "证书类型",
    align: "center",
    dataIndex: "certifTypeText",

  },
  {
    title: "证书名称",
    align: "center",
    dataIndex: "certifName",
  },
  {
    title: "考试名称",
    align: "center",
    dataIndex: "examName",
    scopedSlots: { customRender: "examName" },
  },
  {
    title: "考试日期场次",
    align: "center",
    dataIndex: "examDate",
    scopedSlots: { customRender: "examDate" },
  },
  {
    title: "证书编号",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "发证日期",
    align: "center",
    dataIndex: "getcertifTime",
  },
  {
    title: "操作",
    align: "center",
    width: "150px",
    fixed: "right",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox, Derive },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      checkedList: [],
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      isExportShow: false, // 导出弹窗,
      isModifyShow: false, // 更换证书信息弹窗
      isChecked: false,
      selectedRowKeys: [],
      certifName: "", //	证书名称
      certifType: undefined, //	证书类型
      courseName: "", //	课程名称
      examName: "", //	考试名称
      keyword: "", //	关键字
      name: "",
      mobile: "",
      idcard: "",
      userType: [], //	用户类型
      findtime: [], // 筛选时间区间
      dateMode: ['month', 'month'],
      dateStart: '', //发证开始时间
      dateEnd: '', //发证结束时间
      modelForm: {
        birthday: null, // 生日
        certifNumber: "", // 证书编码
        getcertifTime: null,
        education: "", // 学历
        generateTime: null, // 证书生成时间
        post: "", // 职称
        sex: "", // 性别 1.男 0.女
        workunit: "", // 工作单位
      },
      certifItem: {
        examDate: null,
        examName: null,
        mobile: null,
        certifTypeText: null,
        certifType: null,
        examDateTime: null,
        userId: null,
        picture: null,
        examineeId: null,
        examEndDate: null,
        courseName: null,
        examStartDate: null,
        idcard: null,
        name: null,
        certifId: null,
        userType: null,
        // 操作对象
      },
      exportList: [
        { name: "用户姓名", value: "name", checked: true, disabled: false },
        { name: "手机号", value: "mobile", checked: true, disabled: false },
        { name: "身份证", value: "idcard", checked: true, disabled: false },
        { name: "性别", value: "sex", checked: true, disabled: false },
        { name: "学历", value: "education", checked: true, disabled: false },
        { name: "证书编号", value: "code", checked: true, disabled: false },
        {
          name: "发证时间",
          value: "getcertifTime",
          checked: true,
          disabled: false,
        },
        { name: "身份", value: "userTypeText", checked: true, disabled: false },
        { name: "职称", value: "post", checked: true, disabled: false },
        { name: "生日", value: "birthday", checked: true, disabled: false },
        { name: "工作单位", value: "workUnit", checked: true, disabled: false },
        { name: "所在地", value: "address", checked: true, disabled: false },
        { name: "用户名", value: "userName", checked: true, disabled: false },
        { name: "考试日期", value: "examDate", checked: true, disabled: false },
        {
          name: "考试场次",
          value: "examDateTime",
          checked: true,
          disabled: false,
        },
        { name: "考试名称", value: "examName", checked: true, disabled: false },
        {
          name: "证书类型",
          value: "certifTypeText",
          checked: true,
          disabled: false,
        },
        {
          name: "课程名称",
          value: "courseName",
          checked: true,
          disabled: false,
        },
      ],
    };
  },
  // 事件处理器
  methods: {
    // 发证时间
    handleChange(value) {
      console.log(value)
      this.findtime = value
    },
    handlePanelChange2(value, mode) {
      this.findtime = value
      this.dateMode = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
    },
    modelCancel() {
      this.isExportShow = false;
      this.$refs.export.checkedList(); 
    },
    // 导出功能
    onexportList() {
      // 转化日期格式
      if (this.findtime.length) {
        this.dateStart = this.dateFormat('yyyy-MM', this.findtime[0]._d)
        this.dateEnd = this.dateFormat('yyyy-MM', this.findtime[1]._d)
      } else {
        this.dateStart = ''
        this.dateEnd = ''
      }

      // console.log(this.exportList);
      let filter = this.exportList.filter((item) => {
        return item.checked;
      });
      let arr = filter?.map((item, index) => {
        if (item.checked) {
          return {
            name: item.name,
            value: item.value,
          };
        }
      });
      window.open(
        this.$config.target + "/hxclass-management/exam/certif/export/certif/query?columnJson=" +
          encodeURIComponent(JSON.stringify(arr)) +
          "&certifName=" +
          encodeURIComponent(this?.certifName) +
          "&certifType=" +
          encodeURIComponent(!this.certifType ? "" : this.certifType) +
          "&courseName=" +
          encodeURIComponent(this?.courseName) +
          "&examName=" +
          encodeURIComponent(this?.examName) +
          "&name=" +
          encodeURIComponent(this?.name) +
          "&mobile=" +
          encodeURIComponent(this?.mobile) +
          "&idcard=" +
          encodeURIComponent(this?.idcard) +
          "&userType=" +
          encodeURIComponent(
            this.userType.length ? this.userType.join(",") : ""
          ) +
          "&startDate=" +
          encodeURIComponent(this.dateStart) +
          "&endDate=" +
          encodeURIComponent(this.dateEnd)
          // "&startDate=" +
          // encodeURIComponent(this.findtime.length ? this.findtime[0] : "") +
          // "&endDate=" +
          // encodeURIComponent(this.findtime.length ? this.findtime?.[1] : "")
      );
    },
    codeChange(e) {
      this.checkedList = e;
    },
    // 查看图片
    lookImg(e) {
      window.open(e);
    },
    // 下载
    getDown(imgsrc, name) {
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },
    // 详情
    goPage() {
      this.$router.push({ path: "/admin/Search/UserDetail" });
    },
    select(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length == this.tableData.length) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      // console.log(selectedRowKeys);
    },
    selectAll(selectedRows) {
      this.selectedRows = selectedRows;
    },
    //全选
    onChange(e) {
      if (e.target.checked == true) {
        this.isChecked = true;
        let id = this.tableData.map((item) => {
          return item.id;
        });
        this.selectedRowKeys = id;
      } else {
        this.isChecked = false;
        this.selectedRowKeys = [];
      }
      // console.log(`checked = ${e.target.checked}`);
    },
    cancel() {
      this.isModifyShow = false;
      this.modelForm = {
        birthday: null, // 生日
        certifNumber: "", // 证书编码
        education: "", // 学历
        generateTime: null, // 证书生成时间
        post: "", // 职称
        sex: "", // 性别 1.男 0.女
        workunit: "", // 工作单位
      };
    },
    // 更换证书信息
    changeCertifMessage() {
      this.modelForm.getcertifTime = this.modelForm.getcertifTime +'-01'
      this.modelForm.certifNumber = this.modelForm.certifcode
      this.modelForm.generateTime = this.modelForm.getcertifTime
      this.modelForm.workunit =  this.modelForm.workUni
      if (!this.modelForm.certifNumber) {
        return this.$message.warning("请输入证书编号");
      } else if (!this.modelForm.generateTime) {
        return this.$message.warning("请选择发证时间");
      } else if (this.modelForm.sex === "") {
        return this.$message.warning("请选择性别");
      } else if (!this.modelForm.birthday) {
        return this.$message.warning("请选择生日");
      } else if (!this.modelForm.education) {
        return this.$message.warning("请输入学历");
      } else if (!this.modelForm.post) {
        return this.$message.warning("请输入职位/职称");
      } else if (!this.modelForm.workunit) {
        return this.$message.warning("请输入工作单位");
      }
      this.$ajax({
        url: "/hxclass-management/exam/certif/eidt/certifinfo",
        method: "put",
        params: {
          ...this.modelForm,
          certifId: this.modelForm.certifId, // 用户电子证书ID
          examineeId: this.modelForm.examineeId, // 考生id
          idcard:this.modelForm.idcard, // 身份证号
          userId: this.modelForm.signupUserId, // 用户ID
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.cancel();
          this.getManageList();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 二维码链接导出
    onExportLink(){
      // 转化日期格式
      if (this.findtime.length) {
        this.dateStart = this.dateFormat('yyyy-MM', this.findtime[0]._d)
        this.dateEnd = this.dateFormat('yyyy-MM', this.findtime[1]._d)
      } else {
        this.dateStart = ''
        this.dateEnd = ''
      }

      window.open(
        this.$config.target +
          "/hxclass-management/exam/certif/certif/RQCodeUrl/export?certifName=" +
          encodeURIComponent(this.certifName) +
          "&certifType=" +
          encodeURIComponent(!this.certifType ? "" : this.certifType) +
          "&courseName=" +
          encodeURIComponent(this.courseName) +
          "&examName=" +
          encodeURIComponent(this.examName) +
          "&name=" +
          encodeURIComponent(this.name) +
          "&mobile=" +
          encodeURIComponent(this.mobile) +
          "&idcard=" +
          encodeURIComponent(this.idcard) +
          "&userType=" +
          encodeURIComponent(
            this.userType.length ? this.userType.join(",") : ""
          ) +
          "&startDate=" +
          encodeURIComponent(this.dateStart) +
          "&endDate=" +
          encodeURIComponent(this.dateEnd)
      );
    },

     // 搜索
    onSearch() {
      this.pageNumber = 1;
      this.getManageList();
    },
    // 证书查询
    getManageList() {
      let userType = [];
      let userTypeStr = '';
      let arry = [
        [1, 4, 5, 7],
        [2, 4, 6, 7],
        [3, 5, 6, 7],
      ];
      // console.log(this.userType);
      if (this.userType != null) {
        this.userType.forEach((element) => {
          if (0 < element) {
            userType.push(...arry[element - 1]);
          } else {
            userType.push(0);
          }
        });
        userTypeStr = Array.from(new Set(userType)).toString();
      }
      // 转化日期格式
      if (this.findtime.length) {
        this.dateStart = this.dateFormat('yyyy-MM', this.findtime[0]._d)
        this.dateEnd = this.dateFormat('yyyy-MM', this.findtime[1]._d)
      } else {
        this.dateStart = ''
        this.dateEnd = ''
      }

      this.loading = true
      this.$ajax({
        url: "/hxclass-management/exam/certif/certif/query",
        method: "get",
        params: {
          certifName: this.certifName, //	证书名称
          certifType: this.certifType, //	证书类型
          courseName: this.courseName, //	课程名称
          examName: this.examName, //	考试名称
          name: this.name,
          mobile: this.mobile,
          idcard: this.idcard,
          userType: userTypeStr, //	用户类型
          // startDate: this.findtime?.[0], // 发证日期开区间
          // endDate: this.findtime?.[1], // 发证日期闭区间
          startDate: this.dateStart, // 发证日期开区间
          endDate: this.dateEnd, // 发证日期闭区间
          current: this.pageNumber, //	页码
          size: this.pageSize, //	条数
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取用户详情
    getUser(e) {
      this.$ajax({
        url: "/hxclass-management/exam/certif/certif-manage/confirm-info",
        method: "get",
        params: {
          examineeId: e.examineeId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.modelForm = res.data;
          this.$set(this.modelForm, "certifId", e.certifId);
          // this.modelForm.push("certifId",e.certifId)
          // console.log(this.modelForm);
          this.isModifyShow = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    dateFormat(fmt, date) {
      let o = {
        "M+": date.getMonth() + 1,                 //月份
        "d+": date.getDate(),                    //日
        "h+": date.getHours(),                   //小时
        "m+": date.getMinutes(),                 //分
        "s+": date.getSeconds(),                 //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds()             //毫秒
      }
        if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
      return fmt
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
  margin-bottom: 10px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
/deep/.right-box {
  padding-bottom: 5px !important;
}
/deep/ .ant-pagination {
  margin: 0;
  position: relative;
  bottom: 40px;
}
.modalDiv {
  > p {
    font-weight: 500;
    color: #333333;
  }
  .code-item {
    > span {
      display: inline-block;
      min-width: 100px;
      margin: 5px 0;
      margin-right: 15px;
    }
  }
}
.all_content_box {
  .line-item {
    margin-bottom: 20px;
    display: flex;
    .all_left_name {
      width: 90px;
      text-align: right;
      margin-right: 20px;
      line-height: 32px;
    }
  }
  .right {
    flex: 1;
  }
  textarea.ant-input {
    flex: 1;
  }
}
</style>
